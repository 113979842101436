import { useContext } from 'react'
import { useParams } from 'react-router-dom';
import PagUnicaAnime from '../components/PagUnicaAnime';
import { Context } from '../context/GlobalState';


const PassPagAnime = () => {

  const { allAnime } = useContext(Context);

  /*dados dinamicos da url*/
  const { id } = useParams();
  var nameBar = id.replace(/[-"]/g, ' ');

  return (
    <div>

      {/*Search tvshows params url*/}
      {allAnime ? allAnime.anime.filter(anime => anime.nome.includes(nameBar)).slice(0, 1).map(anime => <PagUnicaAnime key={anime.name} anime={anime} dados={allAnime} />) : <span>Carregando... Aguarde</span>}

    </div>
  )
}

export default PassPagAnime