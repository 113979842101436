import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './ComponentsCSS/Responsivo.css'
import { GlobalState } from './context/GlobalState.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalState>
      <App />
    </GlobalState>
  </React.StrictMode>,
);

