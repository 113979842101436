/* eslint-disable react/prop-types */
import { motion } from 'framer-motion'
import '../ComponentsCSS/Carousel.css'
import { useRef, useEffect, useState } from 'react';


const CarroselSeries = ({ semelhantes }) => {

  const carousel = useRef();
  const [width, setwidth] = useState(0);

  //tamanho da largura carousel
  useEffect(() => {
    setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
  }, [semelhantes])


  return (
    <div className='carousel_title'>
      <div className='all_carouselMovie'>
        {/*Loading Carousel*/}
        <motion.div ref={carousel} className='carousel' whileTap={{ cursor: 'grabbing' }}>
          <motion.div className='inner' drag="x" dragConstraints={{ right: -180, left: -width }}>
            {semelhantes ? semelhantes.slice(0, 15).map((item) => <motion.div className='item' key={item.nome}> <p>{item.nome}</p> <a href={`/Tvshow/${item.nome}/`}><img className='grid-item' src={item.img} alt='capaFilme' /></a>   </motion.div>) : <span>Carregando... Aguarde</span>}
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default CarroselSeries