import '../ComponentsCSS/Rodape.css'

const Dmca = () => {
  return (
    <div className='dmca'>
      <h1>DMCA</h1>
      <p>
        O site netmovies.win não hospeda nenhuma obra protegida pela Lei de Direitos Autorais em nossos servidores. O que fazemos é apenas indexar de forma automática links dos vídeos que são obtidos através de pesquisas em motores de buscas, como o próprio Google e simplesmente organizamos fazendo a “incorporação” (embed) dos vídeos em nosso site. Nós não nos responsabilizamos pelos arquivos encontrados através desses links externos.
        </p>
        <p>
          <h2>Queremos deixar bem claro algumas informações:</h2>
       <h4>– Os Posters contidos neste site são retirados do site TMDB e do Google.</h4>
       <h4>– As sinopses geralmente são retiradas do site TMDB.</h4>
       <h4> – Os videos contidos aqui são indexados através de links externos, nós não fazemos upload de nada.
        Mesmo não tendo poder sobre os arquivos aqui divulgados, sempre nos comprometemos a responder e tentar resolver todas as solicitações legais de forma pacífica.Entre em contato através do nosso e-mail: suporte@netmovies.win.</h4>
        </p>
        <p>
        Atenciosamente!
        NetMoviesWin
        </p>
        
        <p>

        <h1>DMCA - English</h1>

        We at netmovies.win do not host any work protected by the Copyright Law on our servers. What we do is just get the links from the videos that are obtained through searches in search engines, such as Google itself and simply organize by “embedding” the videos on our site. We are not responsible for the files found through these external links, ie those links outside our site.
        </p>

        <p>
        <h2> We want to clarify some information:</h2>
        <h4>– The posters contained in this site are taken from the site TMDB and Google.</h4>
        <h4>– Synopses are usually  used from the TMDB.</h4>
        <h4> – The videos contained here are indexed through external links, we do not upload anything.
        Even though we do not have power over the files disclosed here, we are always committed to respond and try to resolve all legal requests in a peaceful manner, just contact us through our email: suporte@netmovies.win.</h4>
      
        </p>
       
       <p>
        Regards,
        NetMoviesWin
       </p>

        NetMoviesWin | Filmes e Séries Online HD | 720p | 1080p

    </div>
  )
}

export default Dmca