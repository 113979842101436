import LoadingMovie from './Category/LoadingMovie';
import Marvel from '../Pags/Category/Marvel';
import DC from '../Pags/Category/DC';
import MontagemCarrosel from './MontagemCarrosel';
import { useParams } from 'react-router-dom';

const CategoryContainer = () => {

  const { id } = useParams();

  return (
    <div>

      {/*Search tvshows and movies params url*/}
      {id ? <div className='display_home'><div><MontagemCarrosel /> <LoadingMovie id={id} /> </div></div> : <span></span>}

      {id.includes("Marvel") ? <div className='display_home'><div> <Marvel /> </div></div> : <span></span>}

      {id.includes("DC") ? <div className='display_home'><div> <DC /> </div></div> : <span></span>}

    </div>
  )
}

export default CategoryContainer