/* eslint-disable react/prop-types */
import { useContext, useState } from 'react'
import { useEffect } from 'react';

import '../ComponentsCSS/PagUnica.css'
import '../ComponentsCSS/PagUnicaSerie.css'

import seta from '../assents/seta.svg'
import att from '../assents/refresh.png'
import CarroselSeries from './CarroselSeries';
import { Context } from '../context/GlobalState';

const PagUnicaAnime = ({ anime }) => {

  const { allAnime } = useContext(Context);


  const { nome, sinopse, data, tempo, categoria, img, approval, allTemporadas, temporada1, temporada2, temporada3, temporada4, temporada5, temporada6, temporada7 } = anime;

  const [OnSinopse, setSinopse] = useState(false);
  const [semelhantes, setSemelhantes] = useState();
  const [Lista, setLista] = useState()

  const [temporadaExibida, setTemporadaExibida] = useState(0);

  function exibirTemporada(seasonNumber) {
    setTemporadaExibida(temporadaExibida === seasonNumber ? 0 : seasonNumber);
  }

  function mudarSinopse() {
    if (OnSinopse === false)
      setSinopse(true)
    else {
      setSinopse(false)
    }
  }


  useEffect(() => {


    const timer = setTimeout(() => {

      if (categoria.toLowerCase().includes("ação")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("ação")))
      }
      else if (categoria.toLowerCase().includes("adolescência")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("adolescência")))
      }
      else if (categoria.toLowerCase().includes("animação")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("animação")))
      }
      else if (categoria.toLowerCase().includes("aventura")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("aventura")))
      }
      else if (categoria.toLowerCase().includes("biografia")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("biografia")))
      }
      else if (categoria.toLowerCase().includes("comédia")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("comédia")))
      }
      else if (categoria.toLowerCase().includes("drama")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("drama")))
      }
      else if (categoria.toLowerCase().includes("fantasia")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("fantasia")))
      }
      else if (categoria.toLowerCase().includes("f-Científica")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("f-Científica")))
      }
      else if (categoria.toLowerCase().includes("romance")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("romance")))
      }
      else if (categoria.toLowerCase().includes("suspense")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("suspense")))
      }
      else if (categoria.toLowerCase().includes("terror")) {
        setLista(allAnime.anime.filter((category) => category.categoria.toLowerCase().includes("terror")))

      }


      update();
      //Site + name movie
      document.title = "NetMovies - " + nome;
    }, 200);


    if (timer >= 100) {
      clearTimeout(timer)
    } else {
      return () => clearTimeout(timer);
    }

  }, [allAnime, categoria, nome]);


  function update() {

    if (Lista) {
      for (let i = Lista.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [Lista[i], Lista[j]] = [Lista[j], Lista[i]];
      }
      setSemelhantes(Lista);
    } else {
      return Lista;
    }
    return Lista;
  }


  return (
    <div className='pag_anime'>

      <div className='container_movie'>
        <div>
          <img src={img} alt="img" />
        </div>


        <div>
          <div className='sub_movie'>
            <h1>{nome}</h1>
            <p>{data}</p> <p>{categoria}</p> <p>Temporadas: {tempo}</p> <p>Aprovação: {approval}</p>
          </div>
          <div className='seta'>
            <p className='sinop'>Sinopse</p>
            <div>
              <img onClick={mudarSinopse} alt='sinopse' src={seta} />
            </div>
          </div>

          <h2 className={OnSinopse ? 'sinopseOn' : 'sinopseOF'}>{sinopse}</h2>

          <div className='container_temporada'>

            <nav className='container_menu'>
              <ul className="menu">
                <li className='sub_menuDrop'>Selecionar Temporada  ⇓
                  <ul>
                    {temporada1 ? <li onClick={() => exibirTemporada(1)}>Temporada 1</li> : <span></span>}
                    {temporada2 ? <li onClick={() => exibirTemporada(2)}>Temporada 2</li> : <span></span>}
                    {temporada3 ? <li onClick={() => exibirTemporada(3)}>Temporada 3</li> : <span></span>}
                    {temporada4 ? <li onClick={() => exibirTemporada(4)}>Temporada 4</li> : <span></span>}
                    {temporada5 ? <li onClick={() => exibirTemporada(5)}>Temporada 5</li> : <span></span>}
                    {temporada6 ? <li onClick={() => exibirTemporada(6)}>Temporada 6</li> : <span></span>}
                    {temporada7 ? <li onClick={() => exibirTemporada(7)}>Temporada 7</li> : <span></span>}
                  </ul>
                </li>
              </ul>
            </nav>



            {/*Carregar Toda Temporada*/}
            <div>
              {allTemporadas ? allTemporadas.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temporada Completa:</p></div>
                  <span><a href={ser.link}>Assistir</a></span>
                  <p className='player'>Player Recomendado: Streamtape</p>
                </div>
              )) : []}
            </div>

            {/* Carregar cada temporada */}
            <div>
              {temporadaExibida === 1 ? temporada1.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 1:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : []}
            </div>

            {/* Temporada 2 */}
            <div>
              {temporadaExibida === 2 ? temporada2.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 2:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : []}
            </div>

            {/* Temporada 3 */}
            <div>
              {temporadaExibida === 3 ? temporada3.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 3:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : []}
            </div>

            {/* Temporada 4 */}
            <div>
              {temporadaExibida === 4 ? temporada4.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 4:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : <span></span>}
            </div>

            {/* Temporada 5 */}
            <div>
              {temporadaExibida === 5 ? temporada5.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 5:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : <span></span>}
            </div>

            {/* Temporada 6 */}
            <div>
              {temporadaExibida === 6 ? temporada6.map((ser, index) => (
                <div key={index} className='container_subTemporada'>
                  <div><p>Temp 6:</p></div>
                  <p>Ep:{ser.id}: {ser.ep}</p>
                  <span><a href={ser.link}>Assistir</a></span>
                </div>
              )) : <span></span>}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <h2 className='container_refresh'>Categorias Semelhantes <span onClick={update}> <img src={att} alt="update" /> </span> </h2>
        </div>
        <div className='grid-container'>
          <CarroselSeries semelhantes={semelhantes} />
        </div>
      </div>
      {

        (function () { // DON'T EDIT BELOW THIS LINE
          var d = document, s = d.createElement('script');
          s.src = 'https://https-netmovies-win.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
        })()
      }
      <div id="disqus_thread"></div>


    </div>
  )
}

export default PagUnicaAnime