import '../ComponentsCSS/MeioFilmes.css'
import DetalhesFilmes from '../components/DetalhesFilmes'
import { useContext, useState } from 'react'
import '../ComponentsCSS/MeioFilmes.css'
import { Context } from '../context/GlobalState'



var nomeExibir = 'Exibir +';

const Home = () => {

  const { allMovie, allTvShows } = useContext(Context);


  const [tamanhoF, settamanhoF] = useState(10);
  const [OnSinopse, setSinopse] = useState(false);
  function TamanhoFilmes() {
    if (OnSinopse === false) {
      //tamanho Exibir + -
      setSinopse(true);
      settamanhoF(allMovie.length);
      nomeExibir = 'Exibir -';
    }
    else {
      setSinopse(false);
      settamanhoF(9);
      nomeExibir = 'Exibir +';
    }
  }
  return (

    <div>
      <div>
        <div className='Ultimos'>
          <div className='subMenu_mobile'>
            <a href="/">Filmes - {allMovie ? allMovie.length : <span>0</span>}</a>
            <a href="/Series">Séries - {allTvShows ? allTvShows.serie.length : <span>0</span>}</a>
            {/* <a href="/Anime">Anime - {allAnime ? allAnime.anime.length : <span>0</span>}</a> */}
          </div>
          <div>
            <h2>Últimos Adicionados</h2>
            <div className='grid-container'>
              {allMovie ? allMovie.slice(0, tamanhoF).map((produtos) => <DetalhesFilmes key={produtos.nome} item={produtos} />) : <span>Carregando... Aguarde</span>}
            </div>
            <p className='exibir' onClick={() => TamanhoFilmes()}>{nomeExibir}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home