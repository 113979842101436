import { useContext } from 'react'
import { useState } from 'react';
import DetalhesAnime from '../components/DetalhesAnime';
import { Context } from '../context/GlobalState';


var nomeExibir = 'Exibir +';

const PagAnime = () => {

  const { allMovie, allTvShows, allAnime } = useContext(Context);

  const [tamanhoS, settamanhoS] = useState(10);
  const [OnExibir, setExibir] = useState(false);


  function TamanhoSerie() {
    if (OnExibir === false) {
      //tamanho Exibir + -
      setExibir(true);
      settamanhoS(allTvShows.serie.length);
      nomeExibir = 'Exibir -';
    }
    else {
      setExibir(false);
      settamanhoS(9);
      nomeExibir = 'Exibir +';

    }
  }

  return (
    <>
      {/*SubMenuMobile*/}
      <div className='Ultimos'>

        <div className='subMenu_mobile'>
          <a href="/">Filmes - {allMovie ? allMovie.length : <span>0</span>}</a>
          <a href="/Series">Séries - {allTvShows ? allTvShows.serie.length : <span>0</span>}</a>
          <a href="/Anime">Anime - {allAnime ? allAnime.anime.length : <span>0</span>}</a>
        </div>
        <div>
          <h2>Últimos Animes atualizados/adicionados</h2>
        </div>
        <div className='grid-container'>
          {allAnime ? allAnime.anime.slice(0, tamanhoS).map((produtos) => <DetalhesAnime key={produtos.id} item={produtos} />) : <span>Carregando... Aguarde</span>}
        </div>
        {<p className='exibir' onClick={() => TamanhoSerie()}>{nomeExibir}</p>}
      </div>
    </>
  )
}

export default PagAnime