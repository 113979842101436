
import logo from '../assents/logotipo.png'
import '../ComponentsCSS/Rodape.css'

const Rodape = () => {
  return (
    <div>
      <footer>
        <div className='container_rodape'>
          <img src={logo} alt="logo" />
          <div>
            <p>Somos o assunto quando é qualidade para assistir filmes e séries online.</p>
          </div>
        </div>

        <div className='container_subRodape'>
          <p>NetMovies - © 2023 All rights reserved | O Melhor Site de Filmes e Séries Online</p>
        </div>

        <div className='container_l'>
          <a href="/Dmca">DMCA</a>
        </div>
      </footer>

    </div>
  )
}

export default Rodape