import { useContext } from 'react'
import { useParams } from 'react-router-dom';
import PagUnicaSerie from '../components/PagUnicaSerie'
import { Context } from '../context/GlobalState';

const PassPagSerie = () => {

  const { allTvShows } = useContext(Context);

  /*dados dinamicos da url*/
  const { id } = useParams();
  var nameBar = id.replace(/[-"]/g, ' ');

  return (
    <div>

      {/*Search tvshows params url*/}
      {allTvShows ? allTvShows.serie.filter(serie => serie.nome.includes(nameBar)).slice(0, 1).map(serie => <PagUnicaSerie key={serie.nome} serie={serie} />) : <span>Carregando... Aguarde</span>}



    </div>
  )
}

export default PassPagSerie