/* eslint-disable react/prop-types */
import { useContext, useState } from 'react';
import DetalhesFilmes from '../../components/DetalhesFilmes';
import { Context } from '../../context/GlobalState';


const LoadingMovie = ({ id }) => {


  const { allMovie, allTvShows } = useContext(Context);


  const [tamanhoF, settamanhoF] = useState(10);
  const [nomeExibir, setNomeExibir] = useState('Exibir +');
  const [OnSinopse, setSinopse] = useState(false);

  function TamanhoFilmes() {
    if (OnSinopse === false) {
      setSinopse(true);
      settamanhoF(allMovie.length);
      setNomeExibir('Exibir -');
    } else {
      setSinopse(false);
      settamanhoF(9);
      setNomeExibir('Exibir +');
    }
  }

  const found = allMovie ? allMovie.filter(filme => filme.categoria.includes(id)) : console.log('carregando');

  return (
    <div className='Ultimos'>
      <div className='subMenu_mobile'>
        <a href="/">Filmes - {allMovie?.length}</a>
        <a href="/Series">Séries - {allTvShows?.serie?.length}</a>
        {/* <a href="/Anime">Anime - {allAnime?.anime?.length}</a> */}
      </div>
      <h1>{id}</h1>
      <div className='grid-container'>
        {allMovie ? found.slice(0, tamanhoF).map(filme => <DetalhesFilmes key={filme.nome} item={filme} />) : 'Carregando...Aguarde'}
      </div>
      <p className='exibir' onClick={() => TamanhoFilmes()}>{nomeExibir}</p>
    </div>
  )
}

export default LoadingMovie;