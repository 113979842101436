import React from 'react'
// Hooks and Routes
import { Route, BrowserRouter, Routes } from "react-router-dom";

// CSS
import './App.css';

// Components
import Menu from './components/Menu';
import PassPagFilme from './Pags/PassPagFilme';


// Pages
import MontagemCarroselSerie from './Pags/MontagemCarroselSerie';
import MenuMobile from './components/MenuMobile';
import PassPagSerie from './Pags/PassPagSerie';
import Rodape from './components/Rodape';
import DMCA from './Pags/Dmca';
import { Error404 } from './Pags/Error404';
import MontagemCarrosel from './Pags/MontagemCarrosel';
import PagSeries from './Pags/PagSeries';
import MenuCategoriaPC from './components/MenuCategoriaPC';

// Page Categories
import Home from './Pags/Home';
import PassPagAnime from './Pags/PassPagAnime';
import PagAnime from './Pags/PagAnime';
import CategoryContainer from './Pags/CategoryContainer';

function App() {

  return (
    <div className="App">

      <div>
        <MenuMobile />
        <Menu />
        <MenuCategoriaPC />
        <BrowserRouter>
          <Routes>
            {/*Home*/}
            <Route path='/' element={<><div className='display_home'><div><MontagemCarrosel /> <Home /></div></div> </>} />
            {/*Series*/}
            <Route path='/Series' element={<><div className='display_home'><div><MontagemCarroselSerie /> <PagSeries /></div></div> </>} />
            {/*Anime*/}
            <Route path='/Anime' element={<><div className='display_home'><div><MontagemCarrosel /> <PagAnime /></div></div> </>} />
            {/*nested routes*/}
            <Route exact path={`/Movie/:id`} element={<PassPagFilme />} />
            <Route path={`Tvshow/:id`} element={<PassPagSerie />} />
            <Route path={`Anime/:id`} element={<PassPagAnime />} />
            {/*Categorias*/}
            <Route exact path={`/:id`} element={<CategoryContainer />} />
            <Route path="/Dmca" element={<DMCA />} />
            {/*NO MATCH ROUTE*/}
            <Route path='/*' element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div>
        <Rodape />
      </div>
    </div>
  );
}

export default App;
