import { useContext } from 'react';
import DetalhesFilmes from '../../components/DetalhesFilmes'
import { Context } from '../../context/GlobalState';




const Marvel = () => {


  const { categorySpecial } = useContext(Context);

  return (
    <div className='Ultimos'>
      <div className='grid-container'>
        {categorySpecial ? categorySpecial.dc.map((produtos) => <DetalhesFilmes key={produtos.id} item={produtos} />) : <span>Carregando...Aguarde</span>}
      </div>
    </div>
  )
}

export default Marvel