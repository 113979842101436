import { useContext, useState } from 'react';
import '../ComponentsCSS/Menu.css'
import menuMobile from '../assents/bars-solid.svg'
import lupa from '../assents/lupa.png'
import x from '../assents/x.png'
import DetalhesPesquisa from './DetalhesPesquisa';
import DetalhesPesquisaMovie from './DetalhesPesquisaMovie';
import { Context } from '../context/GlobalState';

var resultadoS;
var resultadoF;

const Menu = () => {

  const { allMovie, allTvShows,setMenuOn } = useContext(Context);

  var [searchSerie, setsearchSerie] = useState([]);
  var [searchFilme, setsearchFilme] = useState([]);
  var [xOn, setXoFF] = useState(lupa);
  var [lupaPesquisa, setlupaPesquisa] = useState(false)
  const [nomeFilme, setNome] = useState();


  function searchAllMovies(e) {
    var valor = e.target.value;
    setNome(valor.toLowerCase())

    setsearchSerie(allTvShows.serie);
    setsearchFilme(allMovie);


    nomeFilme === null ? searchSerie = '' : searchSerie ? resultadoS = searchSerie.filter((filme) => filme.nome.toLowerCase().startsWith(nomeFilme)) : <span></span>;
    nomeFilme === null ? searchFilme = '' : searchFilme ? resultadoF = searchFilme.filter((filme) => filme.nome.toLowerCase().startsWith(nomeFilme)) : <span></span>;
  }


  function barraPesquisa() {

    if (lupaPesquisa === false) {
      setlupaPesquisa(true);
      setXoFF(x)
    }
    else {
      setlupaPesquisa(false)
      setXoFF(lupa)
    }
  }

  return (
    <div className='menu_container_mobile'>
      <header>
        <div className="container">

          {/*Menu Mobile*/}
          <div className='BarraMobile'>
            <div>
              <img src={xOn} alt='lupa' onClick={barraPesquisa} />
            </div>
          </div>

          <div>
            <h1> <a href="/">Netmovies</a> </h1>
          </div>

          <nav>
            <a onClick={setMenuOn}><img src={menuMobile} alt="menuMobile" /></a>
          </nav>
        </div>
        {/*Alterar Barra de Pesquisa*/}
        <div className={lupaPesquisa ? 'lupa' : 'barraOFF'}>
          {/*Resultado Busca*/}
          <input type="Search" placeholder="Buscar Filmes e séries" onChange={searchAllMovies} />

          {resultadoF ? <p className='resultadoBarra'>Filmes{resultadoF ? resultadoF.slice(0, 10).map((produtos) => <DetalhesPesquisaMovie key={produtos.nome} item={produtos} />) : <span></span>}</p> : <span></span>}

          {resultadoS ? <p className='resultadoBarra'>Séries{resultadoS ? resultadoS.slice(0, 10).map((produtos) => <DetalhesPesquisa key={produtos.nome} item={produtos} />) : <span></span>}</p> : <span></span>}

        </div>

      </header>
    </div>
  )
}

export default Menu