import { useParams } from 'react-router-dom';
import PagUnicaFilme from '../components/PagUnicaFilme'
import { useContext } from 'react';
import { Context } from '../context/GlobalState';

const PassPagFilme = () => {

  const { allMovie } = useContext(Context);


  const { id } = useParams();
  var nameBar = id.replace(/[-"]/g, ' ');


  return (
    <div>

      {/*Search movies params url*/}
      {allMovie ? allMovie.filter(filme => filme.nome.includes(nameBar)).slice(0, 1).map(filme => <PagUnicaFilme key={filme.id} filme={filme} />) : <span>Carregando... Aguarde</span>}

    </div>
  )
}

export default PassPagFilme