import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const Context = createContext();

const GlobalState = ({ children }) => {
    const [allMovie, setAllMovie] = useState();
    const [allTvShows, setAllTvShows] = useState();
    const [allAnime, setAllAnime] = useState();
    const [categorySpecial, setCategorySpecial] = useState();
    const [menuOn, setMenuOn] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const resFly = await fetch(
                    "https://netmoviesw.fly.dev/categorias" + "?cache=" + Date.now()
                );
                const resDB = await fetch(
                    "https://netmoviesapi.vercel.app/Api/services"
                );
                const dataFly = await resFly.json();
                const dataDB = await resDB.json();
                setCategorySpecial(dataFly);
                setAllMovie(dataDB.reverse().concat(dataFly.all));
                setAllTvShows(dataFly);
                setAllAnime(dataFly);
            } catch (error) {
                console.log("Ocorreu um erro, tente novamente mais tarde");
            }
        }

        fetchData();
    }, []);

    return (
        <Context.Provider
            value={{
                allMovie,
                allTvShows,
                allAnime,
                setMenuOn,
                menuOn,
                categorySpecial
            }}
        >
            {children}
        </Context.Provider>
    );
};

GlobalState.propTypes = {
    children: PropTypes.node.isRequired
};

export { Context, GlobalState };