import React from 'react';
import { useContext, useState } from 'react'
import { useEffect } from 'react';
import '../ComponentsCSS/PagUnica.css'
import seta from '../assents/seta.svg'
import att from '../assents/refresh.png'
import CarroselFilmes from './CarroselFilmes';
import { Context } from '../context/GlobalState';



const PagUnicaFilme = ({ filme }) => {

  const { allMovie } = useContext(Context);

  const { nome, data, categoria, tempo, approval, sinopse, img, link } = filme;

  const [OnSinopse, setSinopse] = useState(true);
  const [semelhantes, setSemelhantes] = useState();
  const [Lista, setLista] = useState()
  var nomeDaPagina = nome;

  function mudarSinopse() {

    if (OnSinopse === false)
      setSinopse(true)
    else {
      setSinopse(false)
    }
  }

  useEffect(() => {


    const timer = setTimeout(() => {

      if (categoria.toLowerCase().includes("ação")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("ação")))
      }
      else if (categoria.toLowerCase().includes("animação")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("animação")))
      }
      else if (categoria.toLowerCase().includes("aventura")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("aventura")))
      }
      else if (categoria.toLowerCase().includes("biografia")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("biografia")))
      }
      else if (categoria.toLowerCase().includes("comédia")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("comédia")))
      }
      else if (categoria.toLowerCase().includes("f-Científica")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("f-Científica")))
      }
      else if (categoria.toLowerCase().includes("romance")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("romance")))
      }
      else if (categoria.toLowerCase().includes("suspense")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("suspense")))
      }
      else if (categoria.toLowerCase().includes("terror")) {
        setLista(allMovie.filter((category) => category.categoria.toLowerCase().includes("terror")))
      }

      document.title = "NetMovies - " + nomeDaPagina;
      update();

    }, 100);


    if (timer >= 100) {
      clearTimeout(timer)
    } else {
      return () => clearTimeout(timer);
    }

  }, [allMovie, categoria, nomeDaPagina]);

  function update() {

    if (Lista) {
      for (let i = Lista.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [Lista[i], Lista[j]] = [Lista[j], Lista[i]];
      }
      setSemelhantes(Lista);
    } else {
      return Lista;
    }
    return Lista;
  }

  return (
    <div className='pag_filme'>

      <div className='container_movie'>
        <div>
          <img src={img} alt="img" />

        </div>

        <div>
          <div className='sub_movie'>
            <h1>{nome}</h1>
            <p>{data}</p> <p>{categoria}</p> <p>Duração: {tempo}</p> <p>Aprovação: {approval}</p>
          </div>
          <div className='seta'>
            <p className='sinop'>Sinopse</p>
            <div>
              <img onClick={mudarSinopse} alt='sinopse' src={seta} />
            </div>
          </div>
          <h2 className={OnSinopse ? 'sinopseOn' : 'sinopseOF'}>{sinopse}</h2>
          <div>
            <a href={link}><button>Assistir</button></a>
          </div>
        </div>
      </div>


      <div>
        <div>
          <h2 className='container_refresh'>Categorias Semelhantes <span onClick={update}> <img src={att} alt="update" /> </span> </h2>
        </div>
        <div className='grid-container'>
          <CarroselFilmes semelhantes={semelhantes} />
        </div>
      </div>

      {

        (function () { // DON'T EDIT BELOW THIS LINE
          var d = document, s = d.createElement('script');
          s.src = 'https://https-netmovies-win.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
        })()
      }
      <div id="disqus_thread"></div>


    </div>
  )
}

export default PagUnicaFilme